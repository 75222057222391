<template>
  <div>
    <reminder-customer-search />
    <validation-observer ref="simpleRules">
      <b-row v-if="interviewLine.newCustomer === true || interviewLine.id_com_customer !== null">

        <b-col cols="12">
          <company-name />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <tckn />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <name-surname />
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <phone />
        </b-col>
        <template v-if="!getPhoneControl.result">
          <b-col
            v-if="interviewLine.customer_kvkk !== '1'"
            cols="12"
          >
            <template v-if="interviewLine.id_com_customer">
              <kvkk-inline
                :customer-id="interviewLine.id_com_customer"
                :refresh-data="refreshData"
                :card-status="false"
              />
            </template>
            <template v-else>
              <kvkk />
            </template>
            <b-alert
              variant="danger"
              show
            >
              <div class="alert-body text-center">
                KVKK Onayından sonra görüşme notu oluşturabilirsiniz.
              </div>
            </b-alert>
          </b-col>
          <template v-if="interviewLine.customer_kvkk === '1'">
            <b-col
              cols="12"
              md="4"
            >
              <meet />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <interview-subjects />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <interview-statuses />
            </b-col>
            <b-col cols="12">
              <date-time />
            </b-col>
            <b-col cols="12">
              <interview-content />
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col>
                  <small class="text-left">Görüşme oluşturma işlemi "<b>Poliçe Listesi</b>" bölümündeki "<span class="text-warning">Arama Bekliyor</span>" durumunda olan poliçelere uygulanır.</small>
                  <div>
                    <small class="text-left">Görüşme tarihi, seçilen günün görüşme sayısına etki eder.</small>
                  </div>
                  <div>
                    <small class="text-left text-danger">"<b>Hatırlatma Araması</b>" konu ve "<b>Hatırlatma Araması Yapıldı</b>" durumu seçilmeyen görüşmeler görüşme sayısına etki etmemektedir.</small>
                  </div>
                </b-col>
                <b-col
                  md="auto"
                >
                  <div
                    v-if="interviewLine.customer_kvkk === '1'"
                    class="text-right"
                  >
                    <b-button
                      variant="success"
                      :disabled="getPhoneControl.result || isSaving"
                      @click="submitInterview"
                    >
                      <feather-icon
                        v-if="!isSaving"
                        icon="SaveIcon"
                      />
                      <b-spinner
                        v-if="isSaving"
                        small
                      />
                      Kaydet
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </template>
        </template>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import {
  BAlert, BButton, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import { localize, ValidationObserver } from 'vee-validate'
import ReminderCustomerSearch from '@/views/Insurance/Reminder/Components/View/CustomerSearch.vue'
import Kvkk from '@/views/Insurance/Reminder/Components/View/elements/Kvkk.vue'
import InterviewStatuses from '@/views/Insurance/Reminder/Components/View/elements/InterviewStatuses.vue'
import InterviewContent from '@/views/Insurance/Reminder/Components/View/elements/Content.vue'
import NameSurname from '@/views/Insurance/Reminder/Components/View/elements/NameSurname.vue'
import Tckn from '@/views/Insurance/Reminder/Components/View/elements/Tckn.vue'
import Phone from '@/views/Insurance/Reminder/Components/View/elements/Phone.vue'
import DateTime from '@/views/Insurance/Reminder/Components/View/elements/DateTime.vue'
import CompanyName from '@/views/Insurance/Reminder/Components/View/elements/CompanyName.vue'
import KvkkInline from '@/layouts/components/common/KvkkInline.vue'
import InterviewSubjects from '@/views/Insurance/Reminder/Components/View/elements/InterviewSubjects.vue'
import Meet from '@/views/Insurance/Reminder/Components/View/elements/Meet.vue'

export default {
  name: 'ReminderInterviewCard',
  components: {
    BButton,
    Meet,
    InterviewSubjects,
    KvkkInline,
    CompanyName,
    BCol,
    Phone,
    DateTime,
    BAlert,
    Tckn,
    BRow,
    NameSurname,
    InterviewContent,
    InterviewStatuses,
    Kvkk,
    ReminderCustomerSearch,
    ValidationObserver,
    BSpinner,
  },
  props: {
    getDataRefresh: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
    }
  },
  computed: {
    interviewLine() {
      return this.$store.getters['insuranceReminder/interviewLine']
    },
    detailModal() {
      return this.$store.getters['insuranceReminder/getDetailModal']
    },
    getPhoneControl() {
      return this.$store.getters['customers/getPhoneControl']
    },
    dataList() {
      return this.$store.getters['insuranceReminder/getDataList']
    },
  },
  created() {
    localize('tr')
    this.$store.commit('insuranceReminder/RESET_INTERVIEW_LINE')
  },
  methods: {
    refreshData() {

    },
    submitInterview() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isSaving = true
          if (this.dataList) {
            this.interviewLine.insurances = this.dataList
          }
          this.$store.dispatch('insuranceReminder/saveInterview', this.interviewLine)
            .then(response => {
              if (response.status) {
                this.detailModal.status = false
                this.$swal({
                  icon: 'success',
                  title: 'İşlem Başarılı',
                  text: 'Hatırlatma tamamlama görüşmesi başarı ile oluşturuldu.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.isSaving = false
                this.getDataRefresh()
              } else {
                this.isSaving = false
                this.$swal({
                  icon: 'error',
                  title: 'İşlem Başarısız',
                  text: 'İşlem Başarısız. Daha önce görüşme oluşturulmuş olabilir.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
